import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { defineComponent } from 'vue';
import { PAREreignisPhaseTyp } from '@rose/types';
import { chain, trim, reject, some, map, toInteger, first, find } from 'lodash';
import { isProd, filterForPARView, filterForCPTView, filterForUPTView, parLeistungen as _parLeistungen } from '@rose/common-ui';
import { translateHKPParPhase } from '@rose/base';
export default defineComponent({
  filters: {
    parPhase: function parPhase(phase) {
      return translateHKPParPhase(phase);
    },
    parLeistungen: function parLeistungen(leistungen) {
      return _parLeistungen(leistungen);
    },
    vorschlaege: function vorschlaege(_vorschlaege) {
      return chain(_vorschlaege).orderBy([function (v) {
        return v.anzahlplus;
      }, function (v) {
        return v.regel;
      }], ['desc', 'asc']).map(function (v) {
        return toInteger(v.anzahlplus) > 1 ? "".concat(v.anzahlplus, "x ").concat(v.regel) : v.regel;
      }).join(', ').value();
    }
  },
  props: {
    par: {
      type: Array,
      required: true
    },
    stichworte: {
      type: Array,
      required: false,
      "default": function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      PAREreignisPhaseTyp: PAREreignisPhaseTyp,
      isProd: false,
      selectedView: PAREreignisPhaseTyp.PAR,
      disableUPTEndFilter: false,
      showMoreButton: false,
      showLessButton: false,
      selectedPar: undefined
    };
  },
  computed: {
    showStichworte: function showStichworte() {
      return !chain(this.stichworte).map(function (s) {
        return trim(s.kuerzel);
      }).filter(function (s) {
        return s.length > 0;
      }).isEmpty();
    },
    canSeeHKPMan: function canSeeHKPMan() {
      return true; // TODO: return authStore.getters.viewsAvailable ? authStore.getters.viewsAvailable.includes(ViewID.HKP_MANAGER) : false;
    },
    parGruppenUnfiltered: function parGruppenUnfiltered() {
      if (!this.selectedPar) {
        return [];
      }

      return map(this.selectedPar.gruppen, function (g) {
        return Object.assign(Object.assign({}, g), {
          hide: false
        });
      });
    },
    parGruppenFiltered: function parGruppenFiltered() {
      if (this.selectedView === PAREreignisPhaseTyp.PAR) {
        return filterForPARView(this.parGruppenUnfiltered);
      } else if (this.selectedView === PAREreignisPhaseTyp.CPT) {
        return filterForCPTView(this.parGruppenUnfiltered);
      } else if (this.selectedView === PAREreignisPhaseTyp.UPT) {
        var fr = filterForUPTView(this.parGruppenUnfiltered);
        return this.disableUPTEndFilter ? fr : reject(fr, function (i) {
          return i.hide;
        });
      }

      return [];
    }
  },
  beforeMount: function beforeMount() {
    this.isProd = isProd;
    this.selectedPar = first(this.par);
  },
  methods: {
    update: function update() {
      if (this.selectedPar && this.$options.filters) {
        if (this.selectedView === PAREreignisPhaseTyp.PAR) {
          this.showMoreButton = false;
          this.showLessButton = false;
        } else if (this.selectedView === PAREreignisPhaseTyp.CPT) {
          this.showMoreButton = false;
          this.showLessButton = false;
        } else if (this.selectedView === PAREreignisPhaseTyp.UPT) {
          var fr = filterForUPTView(this.parGruppenUnfiltered);
          this.showMoreButton = this.disableUPTEndFilter ? false : some(fr, function (i) {
            return i.hide;
          });
          this.showLessButton = this.disableUPTEndFilter ? some(fr, function (i) {
            return i.hide;
          }) : false;
        }
      }
    },
    toggleFilter: function toggleFilter() {
      this.disableUPTEndFilter = !this.disableUPTEndFilter;
      this.update();
    },
    copy: function copy(text) {
      console.log(text);
    },
    onChange: function onChange(event) {
      var id = event.target.value;
      var isChanged = this.selectedPar ? this.selectedPar.parstatus.hkp !== id : false;
      this.selectedPar = find(this.par, function (p) {
        return p.parstatus.hkp === id;
      });
      console.log('selected par:', id, this.selectedPar);
    }
  }
});