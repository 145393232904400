import { clientStateStore } from '@/state/clientStateStore';
import { CommonFolgeTermineTerminInfoResolverService } from '../../../common-ui/src/services';
import { ITerminResolveInfo } from '../../../types';
import { collectorApi } from './data';
import { faker } from '@faker-js/faker/locale/de';
import { map } from 'lodash';

export class FolgeTermineTerminInfoResolverService extends CommonFolgeTermineTerminInfoResolverService {
  async loadInfo(ids: string[]) {
    if (clientStateStore.getters.isFakeData) {
      return map(ids, id => {
        const pn: ITerminResolveInfo = {
          extid: id,
          kommentar: faker.lorem.sentence(10),
        };
        return pn;
      });
    } else {
      return collectorApi.bot.resolveTerminInfos(ids, this.name);
    }
  }
}
