import "core-js/modules/es.function.name.js";
import { defineComponent } from 'vue';
import { isEmpty } from 'lodash';
export default defineComponent({
  props: {
    patId: {
      type: String,
      required: false,
      "default": undefined
    },
    importProtocol: {
      type: Object || undefined,
      "default": undefined
    },
    patIds: {
      type: Array || undefined,
      required: true
    }
  },
  computed: {
    importInfo: function importInfo() {
      var patInfo = [];

      if (this.importProtocol && this.selectedID) {
        for (var _i = 0, _arr = [{
          key: 'botProtocol',
          name: 'bot'
        }, {
          key: 'collectorProtocol',
          name: 'collector'
        }]; _i < _arr.length; _i++) {
          var where = _arr[_i];
          var modelinfos = where.name === 'bot' ? this.importProtocol.botProtocol.modelInfo : this.importProtocol.collectorProtocol.modelInfo;
          var models = [];

          for (var model in modelinfos) {
            if (Object.prototype.hasOwnProperty.call(modelinfos, model)) {
              var modelinfo = modelinfos[model];

              if (!isEmpty(modelinfo.patids[this.selectedID])) {
                var extids = modelinfo.patids[this.selectedID];
                models.push({
                  model: model,
                  extids: extids
                });
              }
            }
          }

          patInfo.push({
            where: where,
            models: models
          });
        }
      }

      return patInfo;
    },
    selectedID: {
      get: function get() {
        return this.patId || this.patIds[0];
      },
      set: function set(val) {
        this.$emit('update', val);
      }
    }
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$emit('close');
    }
  }
});