import {
  IPatientResolvedInfo,
  IPatientNameContainer,
  GeschlechtType,
  IPatientNameResolverOptions,
  IPatientNameContainerMitEmail,
} from '../../../types';
import { fakerDe } from '../fakerDe';
import { roseDayjs } from '../../../base';
import { BaseResolverService } from './base-resolver.service';
import { fakeTitle } from '../utils';
import { map } from 'lodash';

export abstract class CommonNameResolverService<TOptions = IPatientNameResolverOptions> extends BaseResolverService<
  IPatientNameContainer,
  IPatientNameContainer,
  IPatientResolvedInfo,
  TOptions
> {
  public name = 'namen';
  public maxRetries = 30;

  extractDataObjects(entry: IPatientNameContainer): IPatientNameContainer[] {
    return entry ? [entry] : [];
  }

  public extractId(entry: IPatientNameContainer) {
    return entry.patid;
  }

  public extractInfoKey(item: IPatientResolvedInfo) {
    return item?.extid;
  }

  public fakeInfo(dobjs: IPatientNameContainer[]) {
    const sex = fakerDe.name.sexType();
    const patInfos = map(dobjs, o => {
      const pn: IPatientResolvedInfo = {
        extid: o.patid,
        titel: fakeTitle(),
        name: fakerDe.name.lastName(sex),
        vorname: fakerDe.name.firstName(sex),
        veraltet: fakerDe.datatype.boolean(),
        privat: fakerDe.datatype.boolean(),
        geburtstag: fakerDe.date.past(50),
        geschlecht: sex === 'male' ? GeschlechtType.MALE : GeschlechtType.FEMALE,
        telefon: [fakerDe.phone.number()],
        email: fakerDe.internet.email(),
      };
      return pn;
    });
    return Promise.resolve(patInfos);
  }

  public blurInfo(dobjs: IPatientNameContainer[]) {
    const sex = fakerDe.name.sexType();
    return Promise.resolve(
      map(dobjs, o => {
        const pn: IPatientResolvedInfo = {
          extid: o.patid,
          name: '******',
          vorname: '******',
          veraltet: fakerDe.datatype.boolean(),
          privat: fakerDe.datatype.boolean(),
          geburtstag: fakerDe.date.past(50),
          geschlecht: sex === 'male' ? GeschlechtType.MALE : GeschlechtType.FEMALE,
          telefon: [fakerDe.phone.number()],
          email: fakerDe.internet.email(),
        };
        return pn;
      }),
    );
  }

  public destroy() {}

  public setResolvedInfo(e: IPatientNameContainerMitEmail, getResolvedInfo: (infoKey: string) => IPatientResolvedInfo) {
    const result = { changedIds: [] as string[], failedIds: [] as string[] };
    const patid = e.patid;
    if (patid) {
      const nameInfo = getResolvedInfo(patid);
      if (nameInfo) {
        // check if we really need this
        if (e.name !== nameInfo.name) {
          const bdayMoment = nameInfo.geburtstag && roseDayjs(nameInfo.geburtstag);
          e.titel = nameInfo.titel;
          e.name = nameInfo.name;
          e.vorname = nameInfo.vorname;
          e.veraltet = nameInfo.veraltet;
          e.privat = nameInfo.privat;
          e.geschlecht = nameInfo.geschlecht;
          e.par = nameInfo.par;
          e.fon = nameInfo.telefon;
          e.email = nameInfo.email;
          if (bdayMoment) {
            e.geburtstag = bdayMoment.toDate();
            e.alterHeute = roseDayjs().diff(bdayMoment, 'years');
          }
          result.changedIds.push(patid);
        }
      } else {
        result.failedIds.push(patid);
      }
    }
    return result;
  }
}
