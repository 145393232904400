import { createSharedComposable, useEventSource, UseEventSourceReturn } from '@vueuse/core';
import { computed } from 'vue';
import { EventStreamConfig, StringArrayToObject, collectorServerEvents } from '@rose/types';
import {
  createCollectorApi,
  createMetricsApi,
  createR4cApi,
  createDoctosyncApi,
  roseAxios,
  SyncAuthRequestStateGetter,
} from '../../../base';
import { provideAuthStateGetter, provideRoseApi } from '@rose/common-ui/src/services/commonApi';
import { authStore } from '@/state/authStore';
import { filter } from 'lodash';

import {
  createRoseApiCommon,
  getCollectorUrl,
  installRequestTimingInterceptor,
  sendCommandToUi,
} from '../../../common-ui';
import { AxiosInstance, AxiosResponse } from 'axios';

const authRequestStateGetter: SyncAuthRequestStateGetter = () => ({
  authToken: authStore.state.authToken,
  cid: authStore.state.cid,
  r4chost: authStore.state.user?.r4chost,
});

const roseApi = createRoseApiCommon({
  stateGetter: authRequestStateGetter,
  installInterceptors: [installAxiosRequestTimingInterceptor],
});

export const metricsApi = createMetricsApi(roseApi);
export const collectorApi = createCollectorApi(roseApi);
export const r4cApi = createR4cApi(roseApi);
export const factoringApi = r4cApi.factoring;
export const doctosyncApi = createDoctosyncApi(roseApi);

provideRoseApi(roseApi);
provideAuthStateGetter(authRequestStateGetter);

// SSE
export const parseEventSourceData = <T>(eventSource: UseEventSourceReturn) => ({
  ...eventSource,
  data: computed(() => {
    const data = eventSource.data.value;
    if (data) {
      return JSON.parse(data) as T;
    } else {
      return null;
    }
  }),
});

function useRoseEventSource<DataType, Params extends readonly string[]>(
  eventStreamConfig: EventStreamConfig<DataType, Params>,
  paramsGetter: () => StringArrayToObject<Params>,
) {
  return createSharedComposable(() => {
    const params = paramsGetter();

    const eventParams = eventStreamConfig.params;
    // check if all params are present
    const missingParams = filter(eventParams, (p: keyof StringArrayToObject<Params>) => !params[p]);
    if (missingParams.length > 0) {
      throw new Error(`missing params for event ${eventStreamConfig.path}: ${missingParams.join(', ')}`);
    }

    const collectorUrl = getCollectorUrl();

    // convert params object to query string
    const queryString = Object.entries({ ...params, jwt: authStore.state.authToken, cid: authStore.state.cid })
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return parseEventSourceData<DataType>(
      useEventSource(`${collectorUrl}/events${eventStreamConfig.path}?${queryString}`),
    );
  });
}

export const useZimmerbelegungEventSource = useRoseEventSource(collectorServerEvents.zimmerBelegung, () => ({}));

declare module 'axios' {
  export interface AxiosRequestConfig {
    metadata?: {
      startTime: Date;
    };
  }
}

export function installAxiosRequestTimingInterceptor(axiosInstance: AxiosInstance) {
  installRequestTimingInterceptor(axiosInstance, {
    requestStarted: () => {
      sendCommandToUi(
        {
          command: 'requestStarted',
        },
        { skipLog: true },
      );
    },
    requestFinished: duration => {
      sendCommandToUi(
        {
          command: 'requestTiming',
          duration,
        },
        { skipLog: true },
      );
      sendCommandToUi(
        {
          command: 'requestEnded',
        },
        { skipLog: true },
      );
    },
  });
}
