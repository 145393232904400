import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "d-flex flex-grow-1 py-2"
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.stichworte,
      "item-text": "name",
      "item-value": "name",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "flat": "",
      "chips": "",
      "clearable": "",
      "multiple": "",
      "label": "Stichworte ignorieren"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('StichwortChip', {
          attrs: {
            "stichwort": data.item
          }
        })];
      }
    }, {
      key: "selection",
      fn: function fn(data) {
        return [_c('StichwortChip', {
          attrs: {
            "stichwort": data.item
          }
        })];
      }
    }]),
    model: {
      value: _vm.regelSetting,
      callback: function callback($$v) {
        _vm.regelSetting = $$v;
      },
      expression: "regelSetting"
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };