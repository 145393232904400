import { map, take, toLower } from 'lodash';
import { GeschlechtType, IApiKey, IPatientResolvedInfo, IRequestResult } from '../../../types';
import { createMetricsApi, createR4cApi, roseDayjs } from '../../../base';

export class CommonR4cService {
  constructor(public metrics: ReturnType<typeof createMetricsApi>, public r4c: ReturnType<typeof createR4cApi>) {}

  async loadApiKey(): Promise<IApiKey | undefined> {
    const result: IRequestResult<IApiKey> = await this.metrics.main.getApiKey();
    if (result.success) {
      return result.data;
    }
    return undefined;
  }

  async resolveNamesWithr4c(apikey: string, ids: string[], limit = 20): Promise<IPatientResolvedInfo[]> {
    if (apikey) {
      const somePatids = limit ? take(ids, limit) : ids;
      const results = await this.r4c.patient.getPatientsNameInfos({ patids: somePatids });
      return map(results, r => ({
        extid: r.extid,
        name: r.name,
        vorname: r.vorname,
        geburtstag: roseDayjs(r.geburtsdatum, 'DD.MM.YYYY').toDate(),
        geschlecht:
          r.geschlecht === 'M'
            ? GeschlechtType.MALE
            : r.geschlecht === 'W'
            ? GeschlechtType.FEMALE
            : GeschlechtType.NEUTRAL,
        stammbehandler: r.stammbehandler,
        privat: toLower(r.versicherungsart) === 'privat',
        prophylaxebehandler: undefined, // TODO patient prophylaxebehandler
        veraltet: false, // TODO patient veraltet
        par: undefined, // TODO add grading
      }));
    }
    return [];
  }
}
