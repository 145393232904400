import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', {
    staticClass: "checkbox-cell text-center"
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.label,
      "dense": "",
      "hide-details": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clicked($event);
      }
    },
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };