import { createCollectorApi, createMetricsApi, createR4cApi, RoseApi, SyncAuthRequestStateGetter } from '../../../base';

let r4cApi: ReturnType<typeof createR4cApi> | null = null;
let metricsApi: ReturnType<typeof createMetricsApi> | null = null;
let collectorApi: ReturnType<typeof createCollectorApi> | null = null;

export function getR4cApi() {
  if (!r4cApi) throw new Error('r4cApi not initialized');
  return r4cApi;
}

export function getMetricsApi() {
  if (!metricsApi) throw new Error('metricsApi not initialized');
  return metricsApi;
}

export function getCollectorApi() {
  if (!collectorApi) throw new Error('collectorApi not initialized');
  return collectorApi;
}

export function provideRoseApi(roseApi: RoseApi) {
  r4cApi = createR4cApi(roseApi);
  metricsApi = createMetricsApi(roseApi);
  collectorApi = createCollectorApi(roseApi);
}

let authRequestStateGetter: SyncAuthRequestStateGetter | null = null;

export function provideAuthStateGetter(_authRequestStateGetter: SyncAuthRequestStateGetter) {
  authRequestStateGetter = _authRequestStateGetter;
}

export function runRequestStateGetter() {
  if (!authRequestStateGetter) throw new Error('authRequestStateGetter not initialized');
  return authRequestStateGetter();
}
