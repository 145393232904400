import { isNil, isNumber } from 'lodash';
import { formatNumber } from '../formatNumber';

export const percentFilter = {
  filters: {
    percent(value: any, fractionDigits = 0, fallback = '-') {
      if (isNil(value)) {
        return fallback;
      }
      if (isNumber(value)) {
        value += 0.00001;
      }
      return formatNumber(value * 100, { style: 'decimal', fractionDigits }) + ' %';
    },
  },
};
